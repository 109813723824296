@font-face {
  font-family: Suisse Intl;
  font-weight: 400;
  font-display: swap;
  src: url("./assets/fonts/SuisseIntl/SuisseIntl-Regular.woff2") format("woff2"),
    url("./assets/fonts/SuisseIntl/SuisseIntl-Regular.woff") format("woff");
}

@font-face {
  font-family: Suisse Intl;
  font-weight: 500;
  font-display: swap;
  src: url("./assets/fonts/SuisseIntl/SuisseIntl-Medium.woff2") format("woff2"),
    url("./assets/fonts/SuisseIntl/SuisseIntl-Medium.woff") format("woff");
}

* {
  font-family: "Suisse Intl", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: inherit;
}

body {
  color: #263b47;
  background: #d8e3ec;
}

html {
  font-size: 87.5%;
}
